<template>
    <div class="header-container">
        <div class='sticky' id="navbar-container">
            <el-menu :default-active="store.activeMenu" mode="horizontal" background-color="transparent"
                text-color="#fbfbfb" active-text-color="#c9a050" :ellipsis="store.isMobile" menu-trigger="hover"
                :class="isSticky ? '' : 'navbar-shadow'">
                <el-menu-item index="0" style="pointer-events: none; font-weight: 600;"><span class="fs-5">Premio
                        Forlano</span></el-menu-item>
                <el-menu-item index="1" @click="$router.push('/'); defaultActive = '1'">HOME</el-menu-item>
                <el-menu-item index="2" @click="$router.push('/galleria'); defaultActive = '2'"
                    :style="store.loggedUser?.ruolo == 'genitore' ? 'margin-right: auto;' : ''">GALLERIA</el-menu-item>
                <el-menu-item index="3"
                    v-if="store.loggedUser?.ruolo == 'artista_minorenne' || store.loggedUser?.ruolo == 'artista_maggiorenne'"
                    @click="$router.push('/portfolio')" style="margin-right: auto;">PORTFOLIO</el-menu-item>
                <el-menu-item index="4"
                    v-if="store.loggedUser?.ruolo == 'amministratore' || store.loggedUser?.ruolo == 'super_amministratore'"
                    @click="$router.push('/gestione-utenti'); defaultActive = '4'" style="margin-right: auto;">GESTIONE
                    UTENTI</el-menu-item>
                <el-sub-menu index="5" v-if="store.loggedUser">
                    <template #title>
                        <el-icon>
                            <UserFilled />
                        </el-icon>
                        {{ store.loggedUser.username }}&nbsp;<span v-if="store.loggedUser.ente"> / {{
                            store.loggedUser.ente.nome }}</span>
                    </template>
                    <el-menu-item index="5-1" @click="$router.push('/gestione-profilo')">Gestione profilo</el-menu-item>
                    <el-menu-item index="5-2" @click="logout()">Esci</el-menu-item>
                </el-sub-menu>
                <el-menu-item index="6" v-if="!store.loggedUser" @click="openLoginDialog()" style="margin-left: auto;">
                    <el-icon>
                        <UserFilled />
                    </el-icon>
                    Accedi
                </el-menu-item>
            </el-menu>
        </div>

        <div class="banner-container">
            <h1 class="banner-text">{{ store.pageTitle }}</h1>
        </div>
    </div>

    <el-dialog v-model="showLoginDialog" :width="store.isMobile ? '300' : '500'" :show-close="false">
        <template #header>
            <div>
                <el-divider content-position="center"><span class="fs-5">Il tuo account</span></el-divider>
            </div>
        </template>
        <el-form :label-position="store.isMobile ? 'top' :'right'" label-width="auto" ref="loginRuleFormRef" :rules="rules" :model="utente">
            <el-row justify="center">
                <el-col :span="20">
                    <el-form-item label="Username" class="pb-2" prop="username" @input="loginMessage = ''">
                        <el-input v-model="utente.username" clearable />
                    </el-form-item>
                    <el-form-item label="Password" class="pb-2" prop="password" @input="loginMessage = ''">
                        <el-input v-model="utente.password" type="password" show-password clearable />
                    </el-form-item>
                    <el-alert v-if="loginMessage" :title="loginMessage" type="warning" center show-icon
                        :closable="false" />
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="info" plain @click="showLoginDialog = false">Annulla</el-button>
                <el-button type="info" @click="login()">
                    Accedi
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup lang="ts">
import { Utente } from "@/models/Utente";
import { onMounted, ref, computed, reactive } from "vue";
import { FormInstance, FormRules } from "element-plus";
import router from "@/router";
import { findUtenteByLogin } from "@/services/utente.service";
import { UserFilled } from '@element-plus/icons-vue'
import { store } from "@/utils/store";
import { closeSpinner, startSpinner } from "@/utils/utils";

const loginRuleFormRef = ref<FormInstance>()

let showLoginDialog = ref(false);
let utente = ref<Utente>(new Utente());
let loginMessage = ref<string>();
let defaultActive = ref("1");
let isSticky = ref(false);

onMounted(() => {
    let logged = sessionStorage.getItem("loggedUser");
    if (logged) {
        store.loggedUser = JSON.parse(logged);
    }
    window.addEventListener("scroll", () => {
        let navbar = document.getElementById("navbar-container");
        if (navbar) {
            if (window.scrollY > 60) {
                isSticky.value = true;
                navbar.style.backgroundColor = "#001719";
            } else {
                isSticky.value = false;
                navbar.style.backgroundColor = "transparent";
            }
        }
    });
})

function openLoginDialog() {
    loginMessage.value = '';
    showLoginDialog.value = true;
    loginRuleFormRef.value?.resetFields();
}

async function login() {
    if (loginRuleFormRef.value) {
        await loginRuleFormRef.value.validate(async (valid) => {
            if (valid) {
                try {
                    startSpinner();
                    utente.value.username = utente.value.username?.trim();
                    let res = await findUtenteByLogin(utente.value);
                    setTimeout(() => {
                        if (res?.data) {
                            resetChildPortfolio();
                            sessionStorage.setItem("loggedUser", JSON.stringify(res.data));
                            store.loggedUser = res.data;
                            resetUser();
                            showLoginDialog.value = false;
                        }
                        closeSpinner();
                    }, 500);
                } catch (error: any) {
                    setTimeout(() => {
                        closeSpinner();
                        loginMessage.value = error.response?.data?.message;
                    }, 500);
                }
            }
        })
    }
}

function resetChildPortfolio() {
    sessionStorage.setItem("idChildPortfolio", '');
    sessionStorage.setItem("childPortfolio", "false");
}

function resetUser() {
    utente.value.username = "";
    utente.value.password = "";
}

function logout() {
    store.loggedUser = null;
    sessionStorage.setItem("loggedUser", "");
    router.push('/');
}

// LOGIN FORM VALIDATION

const validateUsername = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        callback(new Error("Campo obbligatorio"));
    }
}

const validatePassword = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        callback(new Error("Campo obbligatorio"));
    }
}

const ruleForm = reactive({
    username: '',
    password: ''
})

const rules = reactive<FormRules<typeof ruleForm>>({
    username: [{ validator: validateUsername, trigger: "blur" }],
    password: [{ validator: validatePassword, trigger: "blur" }],
})


</script>

<style scoped>
.header-container {
    background-image: url("../assets/header.jpg");
    background-size: cover;
    position: relative;
    background-position: center center;
}

.sticky {
    z-index: 200;
    top: 0;
    bottom: auto;
    position: fixed;
    margin: 0;
    width: 100%;
}

.navbar-shadow {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.7) inset
}

.navbar-sticky {
    position: sticky;
    top: 0;
    z-index: 101;
}

.banner-container {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    height: 350px;
}

.banner-text {
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 3px 3px 5px rgb(20, 20, 20), 0 0 25px grey, 0 0 5px rgb(20, 20, 20);
    color: #f9f9f9;
}
</style>