<template>
    <div>
        <el-footer class="custom-footer">
            <div class="d-flex flex-column flex-md-row justify-content-around align-items-center pt-3">
                <div>
                    <span @click="$router.push('/cookie-policy')" style="cursor: pointer">
                        Cookie policy
                    </span>
                    <span>
                        | Premio Forlano, {{ new Date().getFullYear() }} | tutti i diritti riservati
                    </span>
                </div>
                <img src="../assets/pnrr-tocc.png" style="height: 70px" alt="pnrr-tocc">
            </div>
        </el-footer>
    </div>
</template>

<style scoped>
.custom-footer {
    background-color: #001719;
    color: #fbfbfb;
}

@media screen and (min-width: 768px) {
    .custom-footer {
        height: 100px;
    }
}

@media screen and (max-width: 767px) {
    .custom-footer {
        height: 150px;
    }
}
</style>