import { ElLoading, ElMessage } from "element-plus";

let loadingInstance;

export function showNotification(type, message) {
    ElMessage({
        type: type,
        message: message,
        center: true
    })
}

export function formatText(data: string) {
    return data.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string) {
        return word.toUpperCase();
    })
}

export function scrollToTop() {
    setTimeout(() => {
        const scrollDiv = document.getElementById("navbar-container");
        if (scrollDiv) {
            const offset = scrollDiv.offsetTop;
            window.scrollTo({ top: offset, behavior: "auto" });
        }
    }, 30);
}

export function startSpinner() {
    loadingInstance = ElLoading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
    });
}

export function closeSpinner() {
    if (loadingInstance) {
        loadingInstance.close();
    }
}