<template>
  <HeaderComponent />
  <div style="min-height: 60vh;">
    <RouterView />
    <el-backtop :right="50" :bottom="100" :visibility-height="300" />
  </div>
  <FooterComponent />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import FooterComponent from "./components/FooterComponent.vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import { store } from "./utils/store";

onMounted(() => {
  store.isMobile = screen.width < 768;
})
</script>

<style>
#app {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #fbfbfb;
}

html {
  overflow-y: scroll;
}

body {
  width: 100% !important;
}

:root {
  --el-bg-color: #fbfbfb !important;
  --el-menu-bg-color: #fbfbfb !important;
}

.el-carousel__item[data-v-0061b3ba]:nth-child(2n + 1) {
  background-color: #fbfbfb !important;
}

.el-upload-list__item.is-success {
  display: flex !important;
  justify-content: center !important;
}

.nav-item,
.navbar-brand {
  cursor: pointer;
}

.el-menu--horizontal {
  height: 60px !important;
}

.el-menu--horizontal.el-menu--popup-container {
  height: 100% !important;
}

.el-menu--horizontal .el-menu .el-menu-item {
  font-family: "Montserrat", sans-serif;
}

.el-menu--horizontal .el-menu.el-sub-menu__title {
  background-color: #001719;
}

.el-menu--horizontal.el-menu--popup-container {
  background-color: #001719;
}

.el-menu-item {
  --el-menu-hover-bg-color: #000405 !important;
}

.el-input.el-input--suffix {
  --el-input-focus-border: #324546 !important;
  --el-input-focus-border-color: #324546 !important;
}

.el-input.el-input--prefix {
  --el-input-focus-border: #324546 !important;
  --el-input-focus-border-color: #324546 !important;
}

.el-textarea .el-textarea__inner {
  --el-input-focus-border: #001719 !important;
  --el-input-focus-border-color: #001719 !important;
}

.el-input-number .el-input-number__decrease:hover,
.el-input-number .el-input-number__increase:hover {
  color: #001719 !important;
}

.el-select .el-select__wrapper.is-focused {
  box-shadow: 0 0 0 1px #001719 inset !important;
}

.el-select-dropdown__item {
  font-family: "Montserrat", sans-serif;
}

.el-select-dropdown__item.is-selected {
  color: #c9a050 !important;
}

.el-switch {
  --el-switch-on-color: #c9a050 !important;
}

.el-switch.is-checked .el-switch__core {
  background-color: #c9a050 !important;
  border-color: #c9a050 !important;
}

.el-button.el-button--info {
  --el-button-bg-color: #c9a050 !important;
  --el-button-border-color: #c9a050 !important;
  --el-button-hover-bg-color: #001719 !important;
  --el-button-hover-border-color: #001719 !important;
  --el-button-text-color: #fbfbfb !important;
}

.el-button.el-button--info.is-plain {
  --el-button-bg-color: #fbfbfb !important;
  --el-button-text-color: #001719 !important;
  --el-button-border-color: #c9c8bf !important;
  --el-button-hover-bg-color: #001719 !important;
  --el-button-hover-border-color: #001719 !important;
}

.el-button.el-button--danger.is-plain {
  --el-button-text-color: #ae0000 !important;
  --el-button-border-color: #c9c8bf !important;
  --el-button-hover-bg-color: #ae0000 !important;
  --el-button-hover-border-color: #ae0000 !important;
}

.el-pagination {
  --el-pagination-bg-color: #fbfbfb !important;
  --el-pagination-button-disabled-bg-color: #fbfbfb !important;
}

.el-pager,
.el-pagination button {
  --el-pagination-hover-color: #001719 !important;
}

.el-menu {
  border-right: 0;
}

.el-checkbox {
  --el-checkbox-checked-bg-color: #c9a050 !important;
  --el-checkbox-checked-text-color: #c9a050 !important;
  --el-checkbox-checked-input-border-color: #c9a050 !important;
  --el-checkbox-input-border-color-hover: #c9a050 !important;
}

.el-upload--picture-card,
.el-upload--picture-card:hover {
  border: 1px dashed #c9a050 !important;
}

.el-message.el-message--success {
  background-color: #dfeade !important;
  border-color: #cfe0cd;
}

.el-message .el-icon.el-message__icon.el-message-icon--success {
  color: #609a5a !important;
}

.el-message.el-message--success .el-message__content {
  color: #609a5a !important;
}

.el-loading-spinner .path {
  stroke: #c9a050 !important;
}

.el-backtop:hover {
  color: #001719 !important;
  border: 1px solid #d4d4d4;
  background-color: #ececec !important;
}

.el-backtop {
  color: #ececec !important;
  background-color: #c9a050 !important;
  border: none;
}

.el-carousel .el-carousel__arrow {
  background-color: #c9a050 !important;
}

.el-table th.el-table__cell>.cell.highlight {
  color: #c9a050 !important;
}

.el-table .descending .sort-caret.descending {
  border-top-color: #c9a050 !important;
}

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #c9a050 !important;
}

.el-table-filter__bottom button:hover {
  color: #c9a050 !important;
}

.el-input {
  --el-input-focus-border-color: #c9a050 !important;
}

.el-divider__text {
  background-color: #fbfbfb !important;
}

.el-icon--close-tip {
  display: none !important;
}
</style>