import { Documento } from "./Documento";
import { Ente } from "./Ente";

export class Utente { 
    id?: number;
    nome?: string;
    cognome?: string;
    data_nascita?: Date;
    username?: string;
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
    nickname?: string;
    immagine?: string;
    previousImage?: string;
    biografia?: string;
    email?: string;
    ruolo?: string;
    abilitato?: boolean;
    file_immagine?: File;
    fullUrl?: string; // percorso comprensivo di baseUrl 
    figli: Utente[] = [];
    genitori: Utente[] = [];
    genitore1?: Utente;
    genitore2?: Utente;
    label?: string;
    reverseLabel?: string;
    ente: Ente = new Ente();
    documenti?: Documento[];
    previousDocuments: PreviousDocument[] = [];
}

export class PreviousDocument {
    id?: number;
    percorso?: string;
}